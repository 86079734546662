import React from "react";
import { UserAvatar } from "./UserAvatar";
import { OPEN_MOBILE_MENU_PROFILE_BUTTON } from "../../constants";

type MobileProfileMenuButtonProps = {
  userImage: string;
  onClick: () => void;
};

const MobileProfileMenuButton: React.FC<MobileProfileMenuButtonProps> = ({
  userImage,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      aria-label="Open Mobile Menu Profile View"
      data-testid={OPEN_MOBILE_MENU_PROFILE_BUTTON}
      className="flex laptop:hidden text-sm text-white rounded betterhover:hover:text-green-500 flex px-4 items-center h-full w-fit"
    >
      <UserAvatar
        src={userImage}
        height={200}
        width={200}
        containerClassName="w-fit"
        className="h-5 w-5 object-contain"
      />
    </button>
  );
};

export default MobileProfileMenuButton;
