import React from "react";
import { IconButton } from "../../../../IconButton";
import { IconButtonType } from "../../../../IconButton/types";
import Close from "nvs-ds-icons/react/navigation/Close";
import { useMobileMenuContext } from "../MobileMenuContext";

const CLOSE_MOBILE_MENU_BUTTON = "CLOSE_MOBILE_MENU_BUTTON";

const CloseMenuButton = () => {
  const { onClose } = useMobileMenuContext();

  return (
    <IconButton
      buttonType={IconButtonType.no_outline_no_hover}
      onClick={onClose}
      aria-label={CLOSE_MOBILE_MENU_BUTTON}
      data-testid={CLOSE_MOBILE_MENU_BUTTON}
      className="absolute text-xs text-white betterhover:hover:text-green-500 top-0 right-0 !p-4 z-100"
    >
      <Close color="currentColor" />
    </IconButton>
  );
};

export default CloseMenuButton;
