import clsx from "clsx";
import NavDropdownMenu from "./NavDropdownMenu";
import { NavLink } from "./NavLink";
import { GlobalNavDropdownMenu, GlobalNavHeader } from "../types";
import { useGlobalNavContext } from "./GlobalNavContext";

export const GLOBAL_NAV_ITEM_CLASSNAME = "p-4 flex items-center";

type GlobalNavItemProps = {
  header: GlobalNavHeader;
  dropdownMenu?: GlobalNavDropdownMenu;
};

const GlobalNavItem = ({ header, dropdownMenu }: GlobalNavItemProps) => {
  const { pageClickedEvent } = useGlobalNavContext();
  const isDropdownMenu = header.__typename === "DropdownMenu";

  return (
    <>
      {isDropdownMenu ? (
        <NavDropdownMenu
          title={header.title}
          items={dropdownMenu?.dropdownMenuItemsCollection.items}
        />
      ) : (
        <NavLink
          href={header.url}
          className={clsx(GLOBAL_NAV_ITEM_CLASSNAME, "text-sm uppercase")}
          onClick={() => {
            pageClickedEvent && pageClickedEvent(header.text);
            header.onClick && header.onClick();
          }}
        >
          {header.text}
        </NavLink>
      )}
    </>
  );
};

export default GlobalNavItem;
