import GlobalNavContainer from "./components/GlobalNavContainer";
import MainNavSection from "./components/MainNavSection";
import ProfileNavSection from "./components/ProfileNavSection";
import MobileMenu from "./components/MobileMenu";
import { useMobileMenu } from "./components/MobileMenu/useMobileMenu";
import { GlobalNavProps } from "./types";
import { GlobalNavProvider } from "./components/GlobalNavContext";

const GlobalNav = ({ className, ...props }: GlobalNavProps) => {
  const mobileMenu = useMobileMenu();

  return (
    <GlobalNavContainer className={className}>
      <GlobalNavProvider {...props}>
        <MainNavSection onMainMenuClick={mobileMenu.openMainMenu} />
        <ProfileNavSection onProfileMenuClick={mobileMenu.openProfileMenu} />
      </GlobalNavProvider>

      <MobileMenu
        isOpen={mobileMenu.isOpen}
        onClose={mobileMenu.close}
        view={mobileMenu.view}
        isSignedIn={props.isSignedIn}
        onSignInClick={props.onSignInClick}
        onMainMenuClick={mobileMenu.openMainMenu}
        onProfileMenuClick={mobileMenu.openProfileMenu}
        mainMenu={{
          headers: props.headers,
          dropdownMenus: props.dropdownMenus,
        }}
        profileMenu={props.profileMenu}
        pageClickedEvent={props.pageClickedEvent}
        LinkComponent={props.LinkComponent}
      />
    </GlobalNavContainer>
  );
};

export default GlobalNav;
