import React from "react";
import MainMenu from "./MainMenu";
import ProfileMenu from "./ProfileMenu";
import MenuContainer from "./components/MenuContainer";
import CloseMenuButton from "./components/CloseMenuButton";
import {
  GlobalNavDropdownMenu,
  GlobalNavHeader,
  GlobalNavProfileMenu,
} from "../../types";
import { MobileMenuProvider } from "./MobileMenuContext";

export enum MobileMenuView {
  Main = "Main",
  Profile = "Profile",
}

export type MobileMainMenu = {
  headers: GlobalNavHeader[];
  dropdownMenus?: GlobalNavDropdownMenu[];
};

export type MobileMenuProps = {
  isSignedIn: boolean | undefined;
  onSignInClick: () => void;
  isOpen: boolean;
  view: MobileMenuView;
  onClose: () => void;
  onMainMenuClick: () => void;
  onProfileMenuClick: () => void;
  mainMenu: MobileMainMenu;
  profileMenu: GlobalNavProfileMenu;
  pageClickedEvent?: (e: any) => void;
  LinkComponent?: React.ForwardRefExoticComponent<any>;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isSignedIn, ...props }) => {
  return (
    <MobileMenuProvider isSignedIn={isSignedIn} {...props}>
      <MenuContainer>
        <MainMenu />
        {isSignedIn && <ProfileMenu />}

        <CloseMenuButton />
      </MenuContainer>
    </MobileMenuProvider>
  );
};

export default MobileMenu;
