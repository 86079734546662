import clsx from "clsx";
import { NavLinkProps } from "../types";

export function Link<T>(props: NavLinkProps<T>) {
  const { LinkComponent, ...rest } = props;
  // use standard <a> if no custom link component passed
  if (!LinkComponent) return <a {...rest}>{props.children}</a>;
  return <LinkComponent {...rest}>{props.children}</LinkComponent>;
}

export function NavLink<T>({
  href,
  className,
  children,
  onClick,
  LinkComponent,
  ...props
}: NavLinkProps<T>) {
  return (
    <Link
      className={clsx(
        "font-roobert text-lg text-white hover:text-green-500 truncate",
        className,
      )}
      onClick={onClick}
      href={href}
      LinkComponent={LinkComponent}
      {...props}
    >
      {children}
    </Link>
  );
}
