import { CircleMask } from "./components/CircleMask";

type UserAvatarProps = {
  containerClassName?: string;
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
};

export function UserAvatar({
  containerClassName,
  src,
  alt = "User Profile image",
  ...props
}: UserAvatarProps) {
  const image = <img src={src} alt={alt} {...props} />;
  return <CircleMask className={containerClassName}>{image}</CircleMask>;
}
