import { useEffect } from "react";

// Custom hook to detect when focus leaves an element or its children
export const useFocusOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
) => {
  useEffect(() => {
    const handleFocusOut = (event: FocusEvent) => {
      // If the ref is valid and the newly focused element is not inside the ref
      if (ref.current && !ref.current.contains(event.relatedTarget as Node)) {
        handler();
      }
    };

    // Listen for focusout events on our ref element
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("focusout", handleFocusOut);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("focusout", handleFocusOut);
      }
    };
  }, [ref, handler]);
};
