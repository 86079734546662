import clsx from "clsx";
import { Plus, Minus } from "nvs-ds-icons/react/navigation";
import { DropDownMenuFolderTextWithIconProps } from "../types";
import { Disclosure } from "@headlessui/react";

export function DropDownMenuFolderTextWithIcon(
  props: DropDownMenuFolderTextWithIconProps,
) {
  return (
    <Disclosure.Button as="span">
      <div
        className={clsx(
          props.className,
          "w-full font-roobert text-xl text-white bg-gray-950 cursor:pointer",
        )}
      >
        <div className="flex justify-between w-full">
          <p className="">{props.title}</p>
          <div className="w-6 h-6 self-center">
            {props.open ? <Minus color="white" /> : <Plus color="white" />}
          </div>
        </div>
      </div>
    </Disclosure.Button>
  );
}
