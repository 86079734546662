import clsx from "clsx";
import React from "react";
import NikeSvg from "./NikeSvg";
import { NavLink } from "./NavLink";

const ROUTE_HOME = "/";

type SwooshHomeLinkProps = {
  className?: string;
  onClick?: () => void;
};

const SwooshHomeLink: React.FC<SwooshHomeLinkProps> = ({
  className,
  onClick,
}) => (
  <NavLink
    className={clsx("p-4", className)}
    onClick={onClick}
    href={ROUTE_HOME}
  >
    <NikeSvg color="currentColor" classes="w-[51px] h-[14px]" />
  </NavLink>
);

export default SwooshHomeLink;
