import clsx from "clsx";
import { PropsWithChildren } from "react";

type GlobalNavProps = {
  className?: string;
} & PropsWithChildren;

const GlobalNavContainer = ({ className, children }: GlobalNavProps) => {
  return (
    <nav
      className={clsx(
        className,
        "mt-3 md:mt-6 w-full z-globalNav flex justify-between h-12",
      )}
    >
      {children}
    </nav>
  );
};

export default GlobalNavContainer;
