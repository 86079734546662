import clsx from "clsx";
import { NavLinkProps } from "../types";

export function NavLink<T>({
  url,
  className,
  text,
  onClick,
  LinkComponent,
  ...props
}: NavLinkProps<T>) {
  if (!!LinkComponent)
    return (
      <LinkComponent
        className={clsx(
          "font-roobert text-lg text-white hover:text-green-500 truncate",
          className,
        )}
        onClick={onClick}
        href={url}
        {...props}
      >
        {text}
      </LinkComponent>
    );

  return (
    <a
      className={clsx(
        "font-roobert text-lg text-white hover:text-green-500 truncate",
        className,
      )}
      onClick={onClick}
      href={url}
      {...props}
    >
      {text}
    </a>
  );
}
