import React from "react";
import { GlobalNavProfileMenu } from "../../../types";
import { NavLink } from "../../NavLink";
import { useMobileMenuContext } from "../MobileMenuContext";

type ProfileMenuItemsProps = GlobalNavProfileMenu;

const ProfileMenuItems: React.FC<ProfileMenuItemsProps> = ({
  swooshHandle,
  links,
}) => {
  const { pageClickedEvent } = useMobileMenuContext();
  return (
    <div className="mt-9 ml-2">
      <h1 className="text-xl font-medium font-roobert mb-6 capitalize">
        {swooshHandle}
      </h1>

      <div className="ml-4 flex flex-col gap-4">
        {links.map((link) => {
          return (
            <NavLink
              href={link.url}
              key={link.text}
              onClick={() => {
                pageClickedEvent && pageClickedEvent(link.text);
                link.onClick && link.onClick();
              }}
            >
              {link.text}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileMenuItems;
