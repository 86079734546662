import { DropDownMenuFolderTextWithIcon } from "./components/DropDownMenuFolderTextWithIcon";
import { DropDownMenuPanel } from "./components/DropDownMenuPanel";
import { Disclosure } from "@headlessui/react";
import { DropDownMenuFolderProps } from "./types";
import clsx from "clsx";

const DropDownMenuFolder = (
  props: React.PropsWithChildren<DropDownMenuFolderProps>,
) => {
  return (
    <div className={clsx(props.className && props.className, "bg-gray-950")}>
      <Disclosure defaultOpen={props.defaultOpen}>
        {({ open }) => (
          <>
            <DropDownMenuFolderTextWithIcon title={props.title} open={open} />
            <DropDownMenuPanel>{props.children}</DropDownMenuPanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default DropDownMenuFolder;
