import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";

type Props = {
  children: React.ReactNode;
  show: boolean;
};

const DropdownMenuTransition: React.FC<Props> = ({ children, show }) => (
  <Transition
    show={show}
    as={Fragment}
    enter="transition ease-out duration-75"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
  >
    <Transition.Child>{children}</Transition.Child>
  </Transition>
);

export default DropdownMenuTransition;
