import React from "react";
import { IconButton } from "../../../../IconButton";
import { IconButtonType } from "../../../../IconButton/types";
import { CaretLeft } from "nvs-ds-icons/react/navigation";

type MainMenuButtonProps = {
  onClick: () => void;
};

const MainMenuButton: React.FC<MainMenuButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      aria-label="Back to Main Menu Button"
      className="flex gap-3 items-center uppercase text-sm text-white betterhover:hover:text-green-500 w-28"
    >
      <CaretLeft color="currentColor" className="w-5 h-5" />
      <p>All</p>
    </button>
  );
};

export default MainMenuButton;
