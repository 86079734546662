import React from "react";
import GlobalNavSection from "../GlobalNavSection";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import SignInButton from "../SignInButton";
import MobileProfileMenuButton from "./MobileProfileMenuButton";
import { SkeletonUser } from "./SkeletonUser";
import { useGlobalNavContext } from "../GlobalNavContext";
import { PROFILE_NAV_TEST_ID } from "../../constants";

type ProfileNavSectionProps = {
  onProfileMenuClick: () => void;
};

const ProfileNavSection: React.FC<ProfileNavSectionProps> = ({ ...props }) => {
  return (
    <GlobalNavSection testId={PROFILE_NAV_TEST_ID}>
      <InnerProfileNavComponent {...props} />
    </GlobalNavSection>
  );
};

function InnerProfileNavComponent({
  onProfileMenuClick,
}: ProfileNavSectionProps) {
  const { isSignedIn, profileMenu, onSignInClick } = useGlobalNavContext();

  if (isSignedIn === undefined) return <SkeletonUser />;

  if (!isSignedIn)
    return (
      <SignInButton
        onSignInClick={onSignInClick}
        className="bg-green-500 text-black betterhover:hover:border-[#92CC22] betterhover:hover:bg-[#92CC22] px-4"
      >
        <p className="hidden laptop:block">Sign In</p>
      </SignInButton>
    );

  return (
    <>
      <ProfileDropdownMenu
        userImage={profileMenu.userImage}
        swooshHandle={profileMenu.swooshHandle}
        links={profileMenu.links}
      />
      <MobileProfileMenuButton
        userImage={profileMenu.userImage}
        onClick={onProfileMenuClick}
      />
    </>
  );
}

export default ProfileNavSection;
