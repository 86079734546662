import { Disclosure } from "@headlessui/react";
import { LinksList } from "./LinksList";
import { NestedDropDownMenuPanelProps } from "../types";

export const NestedDropDownMenuPanel = (
  props: NestedDropDownMenuPanelProps,
) => {
  return (
    <Disclosure.Panel>
      <LinksList
        items={Object.values(props.dropdownMenuItemsCollection.items)}
        linkOnClick={props.linkOnClick}
        LinkComponent={props.LinkComponent}
      />
    </Disclosure.Panel>
  );
};
