import clsx from "clsx";
import { PropsWithChildren } from "react";

type GlobalNavSectionProps = {
  className?: string;
  testId?: string;
} & PropsWithChildren;

const GlobalNavSection = ({
  children,
  className,
  testId,
}: GlobalNavSectionProps) => {
  return (
    <div
      className={clsx(
        "h-12 w-fit bg-gray-950 rounded-md flex items-centerfont-roobert font-medium shadow-[0px_2px_6px_#11111120]",
        className,
      )}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

export default GlobalNavSection;
