import React from "react";
import { IconContainer } from "../../IconContainer";
import Profile from "nvs-ds-icons/react/core/Profile";
import clsx from "clsx";

type SignInButtonProps = React.PropsWithChildren<{
  className?: string;
  onSignInClick: () => void;
}>;

const SignInButton: React.FC<SignInButtonProps> = ({
  onSignInClick,
  className,
  children,
}) => (
  <button
    className={clsx(
      className,
      "h-full uppercase text-sm flex items-center gap-2.5 rounded",
    )}
    onClick={onSignInClick}
    aria-label="Sign In Button"
  >
    <IconContainer>
      <Profile />
    </IconContainer>
    {children}
  </button>
);

export default SignInButton;
