import clsx from "clsx";
import { Plus, Minus } from "nvs-ds-icons/react/navigation";
import { NestedDropDownMenuFolderProps } from "../types";
import { Disclosure } from "@headlessui/react";

const FolderTextWithIcon: React.FC<{ open: boolean; title: string }> = ({
  open,
  title,
}) => (
  <div className="flex justify-between w-full">
    <p className="self-center mt-auto align-middle text-sm text-gray-300">
      {title}
    </p>
    <div className="laptop:invisible w-6 h-6 self-center">
      {open ? <Minus color="#CACACB" /> : <Plus color="#CACACB" />}
    </div>
  </div>
);

export function NestedDropDownMenuFolder(props: NestedDropDownMenuFolderProps) {
  return (
    <Disclosure.Button as="span" className="laptop:pointer-events-none">
      <div
        className={clsx(
          props.className,
          "w-full font-helvetica-mono uppercase text-xl",
        )}
      >
        <FolderTextWithIcon open={props.open} title={props.title} />
      </div>
    </Disclosure.Button>
  );
}
