import React from "react";
import ViewContainer from "./components/ViewContainer";
import ViewTransition from "./components/ViewTransition";
import ProfileMenuButton from "./components/ProfileMenuButton";
import MainMenuItems from "./components/MainMenuItems";
import SignInButton from "../SignInButton";
import { useMobileMenuContext } from "./MobileMenuContext";
import { MobileMenuView } from ".";
import { MOBILE_MENU_MAIN } from "../../constants";

const MainMenu = () => {
  const {
    isSignedIn,
    onSignInClick,
    onProfileMenuClick,
    profileMenu,
    mainMenu,
    view,
  } = useMobileMenuContext();

  const isCurrentView = view === MobileMenuView.Main;

  return (
    <ViewTransition show={isCurrentView}>
      <ViewContainer testid={MOBILE_MENU_MAIN}>
        {!isSignedIn ? (
          <SignInButton
            onSignInClick={onSignInClick}
            className="betterhover:hover:text-green-500 w-fit"
          >
            <p>Sign In</p>
          </SignInButton>
        ) : (
          <ProfileMenuButton
            onClick={onProfileMenuClick}
            userImage={profileMenu.userImage}
            swooshHandle={profileMenu.swooshHandle}
          />
        )}

        <MainMenuItems {...mainMenu} />
      </ViewContainer>
    </ViewTransition>
  );
};

export default MainMenu;
