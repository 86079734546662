import React from "react";
import NavDropdownMenu from "../NavDropdownMenu";
import ProfileImageAndHandle from "./ProfileImageAndHandle";
import { GlobalNavLink } from "../../types";

type ProfileDropdownMenuProps = {
  swooshHandle?: string;
  userImage: string;
  links: GlobalNavLink[];
};

const ProfileDropdownMenu: React.FC<ProfileDropdownMenuProps> = ({
  swooshHandle,
  userImage,
  links,
}) => {
  return (
    <div className="laptop:flex h-full hidden">
      <NavDropdownMenu
        align="right"
        altTitle="user-handle"
        title={
          <ProfileImageAndHandle
            userImage={userImage}
            swooshHandle={swooshHandle}
          />
        }
        items={links}
      />
    </div>
  );
};

export default ProfileDropdownMenu;
