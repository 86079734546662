import React from "react";
import GlobalNavSection from "./GlobalNavSection";
import SwooshHomeLink from "./SwooshHomeLink";
import MobileMainMenuButton from "./MobileMainMenuButton";
import GlobalNavItems from "./GlobalNavItems";
import { useGlobalNavContext } from "./GlobalNavContext";
import { MAIN_NAV_TEST_ID } from "../constants";

type MainNavSectionProps = {
  onMainMenuClick: () => void;
};

const MainNavSection: React.FC<MainNavSectionProps> = ({ onMainMenuClick }) => {
  const { headers, dropdownMenus } = useGlobalNavContext();

  return (
    <GlobalNavSection className="px-1.5" testId={MAIN_NAV_TEST_ID}>
      <SwooshHomeLink />
      <GlobalNavItems headers={headers} dropdownMenus={dropdownMenus} />

      <MobileMainMenuButton onClick={onMainMenuClick} />
    </GlobalNavSection>
  );
};

export default MainNavSection;
