import clsx from "clsx";
import { useGlobalNavContext } from "./GlobalNavContext";

export type NavLinkProps<T> = React.PropsWithChildren<{
  href: string;
  className?: string;
  onClick?: () => void;
}>;

export function Link<T>(props: NavLinkProps<T>) {
  const { LinkComponent } = useGlobalNavContext();
  // if no custom link component passed to Global Nav, use standard <a> link
  if (!LinkComponent) return <a {...props}>{props.children}</a>;
  return <LinkComponent {...props}>{props.children}</LinkComponent>;
}

export function NavLink<T>({
  href,
  className,
  children,
  ...props
}: NavLinkProps<T>) {
  const sharedProps = {
    className: clsx(
      className,
      "font-roobert font-medium text-white text-left betterhover:hover:text-green-500 text-lg",
    ),
    "data-testid": "nav-link-" + children?.toString().toLowerCase(),
  };

  if (!href) {
    return (
      <button {...sharedProps} {...props}>
        {children}
      </button>
    );
  }

  return (
    <Link href={href} {...sharedProps} {...props}>
      {children}
    </Link>
  );
}
