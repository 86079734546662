import { NavLink } from "./NavLink";
import { LinksListProps } from "../types";

export const LinksList = (props: LinksListProps) => {
  return (
    <div className="flex flex-col gap-y-2.5">
      {props.items.map((item) => {
        return (
          <NavLink
            href={item.url}
            key={item.text}
            onClick={() => {
              props.linkOnClick && props.linkOnClick(item.text);
              item.onClick && item.onClick();
            }}
            LinkComponent={props.LinkComponent}
          >
            {item.text}
          </NavLink>
        );
      })}
    </div>
  );
};
