import { HTMLAttributes, PropsWithChildren } from "react";

type CircleMaskProps = HTMLAttributes<HTMLDivElement>;

export function CircleMask({
  children,
  ...props
}: PropsWithChildren<CircleMaskProps>) {
  return (
    <div
      style={{
        clipPath: "circle(40%)",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
