import clsx from "clsx";
import React, { PropsWithChildren } from "react";

type ViewContainerProps = PropsWithChildren & {
  className?: string;
  testid?: string;
};

const ViewContainer: React.FC<ViewContainerProps> = ({
  children,
  className,
  testid,
}) => {
  return (
    <div className={clsx("p-4 flex flex-col", className)} data-testid={testid}>
      {children}
    </div>
  );
};

export default ViewContainer;
