import DropdownMenuPopover, {
  DropdownMenuPopoverProps,
} from "../../DropdownMenuPopover";
import { GlobalNavDropdownMenuItems } from "../types";
import { NavLink } from "./NavLink";
import NestedDropDownMenu from "../../NestedDropDownMenu";
import { NestedDropDownMenuItemsCollection } from "../../NestedDropDownMenu/types";
import { useGlobalNavContext } from "./GlobalNavContext";
import clsx from "clsx";

type NavDropdownMenuItems = {
  items?: GlobalNavDropdownMenuItems;
};

type NavDropdownMenuProps = DropdownMenuPopoverProps & NavDropdownMenuItems;

const NavDropdownMenu = ({
  title,
  altTitle,
  items,
  align,
}: NavDropdownMenuProps) => {
  return (
    <DropdownMenuPopover
      title={title}
      altTitle={altTitle}
      align={align}
      isLoading={items === undefined || items.length === 0}
    >
      <NavDropdownMenuItems items={items} />
    </DropdownMenuPopover>
  );
};

function NavDropdownMenuItems({ items }: NavDropdownMenuItems) {
  const { pageClickedEvent, LinkComponent } = useGlobalNavContext();

  return (
    <>
      {items?.map((item) => {
        const isNestedDropdownMenu = item.__typename === "DropdownMenu";

        if (isNestedDropdownMenu) {
          return (
            <NestedDropDownMenu
              key={item.title}
              title={item.title}
              defaultOpen={true}
              dropdownMenuItemsCollection={
                item.dropdownMenuItemsCollection as NestedDropDownMenuItemsCollection
              }
              className="mb-6 last:mb-0"
              linkOnClick={pageClickedEvent}
              LinkComponent={LinkComponent}
            />
          );
        } else {
          return (
            <NavLink
              key={item.text}
              href={item.url}
              data-testid={"nav-dropdown-item-" + item.text.toLocaleLowerCase()}
              className={clsx("truncate mb-1.5 last:mb-0", item.className)}
              onClick={() => {
                pageClickedEvent && pageClickedEvent(item.text);
                item.onClick && item.onClick();
              }}
            >
              {item.text}
            </NavLink>
          );
        }
      })}
    </>
  );
}

export default NavDropdownMenu;
