import { createContext, useContext } from "react";
import { noop } from "@react-hookz/web/cjs/util/const";
import { MobileMenuProps } from ".";

enum MobileMenuView {
  Main = "Main",
  Profile = "Profile",
}

export type MobileMenuContextType = MobileMenuProps;

const MobileMenuContext = createContext<MobileMenuContextType>({
  mainMenu: { headers: [], dropdownMenus: [] },
  isSignedIn: false,
  onSignInClick: noop,
  profileMenu: { swooshHandle: "", userImage: "", links: [] },
  pageClickedEvent: noop,
  isOpen: false,
  onClose: noop,
  onMainMenuClick: noop,
  onProfileMenuClick: noop,
  view: MobileMenuView.Main,
  LinkComponent: undefined,
});

MobileMenuContext.displayName = "MobileMenuContext";

export const MobileMenuProvider = (
  props: React.PropsWithChildren<MobileMenuContextType>,
) => {
  return (
    <MobileMenuContext.Provider
      value={{
        ...props,
      }}
    >
      {props.children}
    </MobileMenuContext.Provider>
  );
};

export const useMobileMenuContext = () => {
  return useContext(MobileMenuContext);
};
