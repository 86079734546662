import { useState } from "react";
import { MobileMenuView } from ".";

export const useMobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState<MobileMenuView>(MobileMenuView.Main);

  const open = (view: MobileMenuView) => {
    setIsOpen(true);
    setView(view);
  };

  const close = () => {
    setIsOpen(false);
  };

  const openMainMenu = () => {
    open(MobileMenuView.Main);
  };
  const openProfileMenu = () => {
    open(MobileMenuView.Profile);
  };

  return {
    isOpen,
    view,
    open,
    close,
    openMainMenu,
    openProfileMenu,
  };
};
