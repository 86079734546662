import axios from "axios";
import { CONTENTFUL_BASE_URL } from "./constants";
import { DropdownMenu } from "nvs-types/dist/types/generated/contentful";

const GLOBAL_NAVIGATION_API = `${CONTENTFUL_BASE_URL}/global-nav-dropdown-menus/`;

export const makeGlobalNavDropdownMenusApiCall = async () => {
  return await axios.get(GLOBAL_NAVIGATION_API).then(({ data }) => {
    const dataItems =
      data.globalNavigationCollection.items[0].globalNavigationItemsCollection
        .items;

    /**
     * GQL endpoint from the content API returns all empty objects for items that are of type Link
     * We only want to return DropdownMenu items here so we filter by type
     * @see https://jira.nike.com/browse/NVS-5222
     */
    const filteredItems = dataItems.filter((item: DropdownMenu) => {
      return item.__typename === "DropdownMenu";
    });

    return filteredItems as DropdownMenu[];
  });
};
