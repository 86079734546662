import React from "react";
import GlobalNavItem from "./GlobalNavItem";
import { GlobalNavDropdownMenu, GlobalNavHeader } from "../types";

type GlobalNavItemsProps = {
  headers: GlobalNavHeader[];
  dropdownMenus?: GlobalNavDropdownMenu[];
};

const GlobalNavItems: React.FC<GlobalNavItemsProps> = ({
  headers,
  dropdownMenus,
}) => {
  return (
    <div className="hidden laptop:flex h-full">
      {headers?.map((header, index) => {
        const isItemDropdownMenu = header.__typename === "DropdownMenu";

        return (
          <GlobalNavItem
            key={"global nav item" + index}
            header={header}
            dropdownMenu={
              isItemDropdownMenu
                ? getDropdownMenuData(header.title, dropdownMenus)
                : undefined
            }
          />
        );
      })}
    </div>
  );
};

export function getDropdownMenuData(
  header: string,
  dropdownMenus?: GlobalNavDropdownMenu[],
) {
  const dropdownMenu = dropdownMenus?.find(
    (dropdown) => dropdown.title.toLowerCase() === header.toLowerCase(),
  );

  return dropdownMenu;
}

export default GlobalNavItems;
