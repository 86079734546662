import React from "react";
import { IconButton } from "../../IconButton";
import { IconButtonType } from "../../IconButton/types";
import { Menu } from "nvs-ds-icons/react/core";
import { OPEN_MOBILE_MENU_MAIN_BUTTON } from "../constants";

type MobileMainMenuButtonProps = {
  onClick: () => void;
};

const MobileMainMenuButton: React.FC<MobileMainMenuButtonProps> = ({
  onClick,
}) => {
  return (
    <IconButton
      buttonType={IconButtonType.no_outline_no_hover}
      onClick={onClick}
      aria-label="Open Mobile Menu Main View"
      data-testid={OPEN_MOBILE_MENU_MAIN_BUTTON}
      className="block laptop:hidden text-2xl text-white betterhover:hover:text-green-500"
    >
      <Menu color="currentColor" />
    </IconButton>
  );
};

export default MobileMainMenuButton;
