import React, { PropsWithChildren, useState } from "react";
import { Popover } from "@headlessui/react";
import clsx from "clsx";
import DropdownMenuTransition from "./components/DropdownMenuTransition";
import DropdownMenuItems from "./components/DropdownMenuItems";
import { useFocusOutside } from "./hooks";

const OUTER_DIV_DEFAULT_CLASSNAME =
  "text-sm text-white w-fit max-h-full relative";

const MENU_BUTTON_OPEN_CLASSNAME = "text-green-500";

const MENU_BUTTON_DEFAULT_CLASSNAME =
  "inline-flex w-fit h-full items-center font-medium uppercase tracking-normal p-4 text-sm hover:text-green-500 active:text-green-500 bg-gray-950 rounded-md";

export type DropdownMenuPopoverProps = PropsWithChildren & {
  title: string | React.ReactNode;
  altTitle?: string; // used as data testId key if menu title/btn is not a string
  classes?: {
    outerDiv?: string;
    menuButton?: {
      open?: string;
      default?: string;
    };
    menuItems?: string;
  };
  isLoading?: boolean; // this is used for data loading
  align?: "left" | "right";
};

const DropdownMenuPopover: React.FC<DropdownMenuPopoverProps> = ({
  classes,
  title,
  altTitle = "",
  isLoading,
  children,
  align = "left",
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const popOverRef = React.useRef<HTMLDivElement>(null);

  useFocusOutside(popOverRef, () => {
    setMouseOver(false);
  });

  return (
    <Popover
      as="div"
      className={classes?.outerDiv ?? OUTER_DIV_DEFAULT_CLASSNAME}
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseOut={() => {
        setMouseOver(false);
      }}
      ref={popOverRef}
    >
      <Popover.Button
        data-open={mouseOver}
        data-active={mouseOver}
        data-testid={typeof title === "string" ? title : altTitle}
        className={clsx(
          mouseOver &&
            (classes?.menuButton?.open ?? MENU_BUTTON_OPEN_CLASSNAME),
          classes?.menuButton?.default ?? MENU_BUTTON_DEFAULT_CLASSNAME,
        )}
        onFocus={() => setMouseOver(true)}
      >
        {title}
      </Popover.Button>

      {!isLoading && (
        <>
          <DropdownMenuTransition show={mouseOver}>
            <DropdownMenuItems
              dropdownTitle={typeof title === "string" ? title : altTitle}
              className={classes?.menuItems}
              align={align}
            >
              {children}
            </DropdownMenuItems>
          </DropdownMenuTransition>
        </>
      )}
    </Popover>
  );
};

export default DropdownMenuPopover;
