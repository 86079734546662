type HandleMaskProps = { className?: string };

export function HandleMask({ className }: HandleMaskProps) {
  return (
    <svg
      width="44"
      height="11"
      viewBox="0 0 44 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="44" height="10" rx="5" fill="#39393B" />
    </svg>
  );
}
