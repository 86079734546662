import { CircleMask } from "./UserAvatar/components/CircleMask";
import { HandleMask } from "./UserAvatar/components/HandleMask";

export function SkeletonUser() {
  return (
    <div
      aria-label="User Content loading..."
      className="flex items-center h-full gap-2.5 px-4"
    >
      <CircleMask className="w-5 h-5 bg-gray-700" />
      <HandleMask className="laptop:block hidden" />
    </div>
  );
}
