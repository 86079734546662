import React from "react";
import DropDownMenuFolder from "../../../../DropDownMenuFolder";
import NestedDropDownMenu from "../../../../NestedDropDownMenu";
import { NavLink as MenuLink } from "../../../../DropDownMenuFolder/components/NavLink";
import { GlobalNavDropdownMenuItems } from "../../../types";
import { useMobileMenuContext } from "../MobileMenuContext";

type MainMenuDropdownProps = {
  title: string;
  items: GlobalNavDropdownMenuItems;
  defaultOpen?: boolean;
};

const MainMenuDropdown: React.FC<MainMenuDropdownProps> = ({
  title,
  items,
  defaultOpen = false,
}) => {
  const { pageClickedEvent, LinkComponent } = useMobileMenuContext();

  return (
    <DropDownMenuFolder
      title={title}
      defaultOpen={defaultOpen}
      className="mb-6"
    >
      {items.map((item: any, index: number) => {
        if (item.__typename == "DropdownMenu") {
          return (
            <NestedDropDownMenu
              dropdownMenuItemsCollection={item.dropdownMenuItemsCollection}
              title={item.title}
              defaultOpen={index === 0}
              key={item.title}
              linkOnClick={pageClickedEvent}
              LinkComponent={LinkComponent}
            />
          );
        } else if (item.__typename == "Link") {
          return (
            <MenuLink
              url={item.url}
              text={item.text}
              key={item.text}
              onClick={() => {
                pageClickedEvent && pageClickedEvent(item.text);
                item.onClick && item.onClick();
              }}
              LinkComponent={LinkComponent}
            />
          );
        }
      })}
    </DropDownMenuFolder>
  );
};

export default MainMenuDropdown;
