import React from "react";
import ProfileImageAndHandle from "../../ProfileNavSection/ProfileImageAndHandle";

type ProfileMenuButtonProps = {
  swooshHandle: string | undefined;
  userImage: string;
  onClick: () => void;
};

const ProfileMenuButton: React.FC<ProfileMenuButtonProps> = ({
  userImage,
  swooshHandle,
  onClick,
}) => {
  return (
    <button onClick={onClick}>
      <ProfileImageAndHandle
        userImage={userImage}
        swooshHandle={swooshHandle}
      />
    </button>
  );
};

export default ProfileMenuButton;
