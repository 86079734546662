import React from "react";
import { MobileMainMenu } from "..";
import { getDropdownMenuData } from "../../GlobalNavItems";
import { NavLink } from "../../NavLink";
import MainMenuDropdown from "./MainMenuDropdown";
import { useMobileMenuContext } from "../MobileMenuContext";

type MainMenuItemsProps = MobileMainMenu;

const MainMenuItems: React.FC<MainMenuItemsProps> = ({
  headers,
  dropdownMenus,
}) => {
  const { pageClickedEvent } = useMobileMenuContext();

  return (
    <div className="mt-9 ml-2">
      {headers.map((header, index) => {
        const isItemDropdownMenu = header.__typename === "DropdownMenu";
        const isItemLink = header.__typename === "Link";

        const dropdownMenuData = isItemDropdownMenu
          ? getDropdownMenuData(header.title, dropdownMenus)
          : undefined;

        return (
          <React.Fragment key={index}>
            {isItemDropdownMenu && dropdownMenuData ? (
              <MainMenuDropdown
                key={dropdownMenuData.title}
                title={dropdownMenuData.title}
                items={dropdownMenuData.dropdownMenuItemsCollection.items}
                defaultOpen={index === 0}
              />
            ) : (
              isItemLink && (
                <NavLink
                  key={header.text}
                  href={header.url}
                  className="text-xl truncate"
                  onClick={() => {
                    pageClickedEvent && pageClickedEvent(header.text);
                    header.onClick && header.onClick();
                  }}
                >
                  {header.text}
                </NavLink>
              )
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MainMenuItems;
