import React, { Fragment, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import { useMobileMenuContext } from "../MobileMenuContext";

type MobileMenuProps = PropsWithChildren;

const MenuContainer: React.FC<MobileMenuProps> = ({ children }) => {
  const { isOpen } = useMobileMenuContext();

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition ease-in-out duration-[400ms]"
      enterFrom="-translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in duration-100"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-full"
    >
      <Transition.Child className="fixed top-0 left-0 w-full h-screen bg-gray-950 text-white overflow-hidden">
        <div className="relative w-full h-full overflow-y-scroll">
          {children}
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default MenuContainer;
