import { Disclosure } from "@headlessui/react";
import { NestedDropDownMenuFolder } from "./components/NestedDropDownMenuFolder";
import { NestedDropDownMenuProps } from "./types";
import { NestedDropDownMenuPanel } from "./components/NestedDropDownMenuPanel";

const NestedDropDownMenu = (props: NestedDropDownMenuProps) => {
  return (
    <div className={props.className}>
      <Disclosure defaultOpen={props.defaultOpen}>
        {({ open }) => (
          <>
            <NestedDropDownMenuFolder title={props.title} open={open} />
            <NestedDropDownMenuPanel
              dropdownMenuItemsCollection={props.dropdownMenuItemsCollection}
              linkOnClick={props.linkOnClick}
              LinkComponent={props.LinkComponent}
            />
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default NestedDropDownMenu;
