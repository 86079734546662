import React, { Fragment, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import clsx from "clsx";

const MAP_DIRECTION = {
  left: {
    from: "-translate-x-full",
    to: "translate-x-0",
  },
  right: {
    from: "translate-x-full",
    to: "translate-x-0",
  },
};

type ViewTransitionProps = {
  show: boolean;
  direction?: "left" | "right";
} & PropsWithChildren;

const ViewTransition: React.FC<ViewTransitionProps> = ({
  show = false,
  direction = "left",
  children,
}) => {
  const dir = MAP_DIRECTION[direction];

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition duration-200"
      enterFrom={dir.from}
      enterTo={dir.to}
      leave="transition duration-200"
      leaveFrom={dir.to}
      leaveTo={dir.from}
    >
      <Transition.Child className={clsx("absolute h-full w-full")}>
        {children}
      </Transition.Child>
    </Transition>
  );
};

export default ViewTransition;
