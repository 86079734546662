import React from "react";
import { UserAvatar } from "./UserAvatar";

type ProfileImageAndHandleProps = {
  swooshHandle?: string;
  userImage: string;
};

const ProfileImageAndHandle: React.FC<ProfileImageAndHandleProps> = ({
  swooshHandle,
  userImage,
}) => {
  return (
    <div className="flex items-center h-full gap-2.5">
      <UserAvatar
        src={userImage}
        height={200}
        width={200}
        containerClassName="w-fit"
        className="h-5 w-5 object-contain"
      />
      <p className="uppercase">{swooshHandle}</p>
    </div>
  );
};

export default ProfileImageAndHandle;
