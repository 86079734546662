import ViewContainer from "./components/ViewContainer";
import ViewTransition from "./components/ViewTransition";
import { useMobileMenuContext } from "./MobileMenuContext";
import MainMenuButton from "./components/MainMenuButton";
import ProfileMenuItems from "./components/ProfileMenuItems";
import { MobileMenuView } from ".";
import { MOBILE_MENU_PROFILE } from "../../constants";

const ProfileMenu = () => {
  const { view, onMainMenuClick, profileMenu } = useMobileMenuContext();
  const isCurrentView = view === MobileMenuView.Profile;

  return (
    <ViewTransition show={isCurrentView} direction="right">
      <ViewContainer testid={MOBILE_MENU_PROFILE}>
        <MainMenuButton onClick={onMainMenuClick} />
        <ProfileMenuItems {...profileMenu} />
      </ViewContainer>
    </ViewTransition>
  );
};

export default ProfileMenu;
