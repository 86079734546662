import {
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_HOME,
  ROUTE_ORDER_HISTORY,
} from "@/constants/routes";
import { getUserProfileRoute } from "@/src/helpers/getUserProfileRoute";
import { noop } from "lodash";
import { signOut } from "next-auth/react";

export const useProfileMenuLinks = (swooshHandle: string | undefined) => {
  if (!!!swooshHandle) return () => {};

  const profileLinks = [
    {
      __typename: "Link",
      text: "Account Settings",
      url: ROUTE_ACCOUNT_SETTINGS,
      onClick: () => {
        noop;
      }, // // global nav page analytics event to go here
    },
    {
      __typename: "Link",
      text: "Orders",
      url: ROUTE_ORDER_HISTORY,
      onClick: noop,
    },
    {
      __typename: "Link",
      text: "Profile",
      url: getUserProfileRoute(swooshHandle),
      onClick: noop, // global nav page analytics event to go here
    },
    {
      __typename: "Link",
      text: "Log Out",
      className: "mt-2",
      onClick: () => signOut({ callbackUrl: ROUTE_HOME }),
    },
  ];
  return profileLinks;
};
